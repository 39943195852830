import React, { lazy } from 'react'
import { EUserRole } from '@unfoldrtech/portal-mic'
import { Navigate, Route } from 'react-router-dom'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { lazyRetry } from '../../utils/lazyRetry'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import {
  PORTAL_FF_DA,
  PORTAL_FF_DIRECT_DEAL_CREATE,
  PORTAL_FF_DOOH_CREATIVES_VIEW,
} from '../../utils/constants'
import { EAllChannelType } from '../../models'

const Advertiser = lazy(() => lazyRetry(() => import('../../pages/Advertiser')))

const AdvertiserOverview = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Overview'))
)

const AdvertiserHome = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Home'))
)

const AdvertiserCreativeOverview = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Creatives/Overview'))
)

const AdvertiserCreativeDisplayAds = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/Creatives/DisplayAds'))
)

const NewCampaign = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/NewCampaign'))
)

const DigitalOutOfHomeCreatives = lazy(() =>
  lazyRetry(
    () =>
      import(
        '../../pages/Advertiser/Routes/Creatives/DigitalOutOfHomeCreatives'
      )
  )
)

const DirectDealsLayout = lazy(() =>
  lazyRetry(
    () => import('../../pages/Retailer/Routes/DirectDeals/DirectDealsLayout')
  )
)

const AdvertiserDirectDeals = lazy(() =>
  lazyRetry(() => import('../../pages/Advertiser/Routes/AdvertiserDirectDeals'))
)

const NotFound = lazy(() => lazyRetry(() => import('../../pages/NotFound')))

const AdvertiserRoutes = () => {
  const featureFlags = useFeatureFlags()

  const isDirectDealsEnabled = featureFlags[PORTAL_FF_DIRECT_DEAL_CREATE]

  return (
    <>
      <PortalRoutes>
        <Route
          path="advertiser/:orgId"
          element={
            <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
              <Advertiser />
            </AuthenticatedRoute>
          }
        >
          <Route
            path="retailer/:retailerId"
            element={
              <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                <AdvertiserOverview />
              </AuthenticatedRoute>
            }
          >
            {isDirectDealsEnabled && (
              <Route path="direct-deals" element={<DirectDealsLayout />}>
                <Route index element={<Navigate to="all-channels" />} />

                <Route
                  path="all-channels"
                  element={<AdvertiserDirectDeals />}
                />

                <Route
                  path="display-ads"
                  element={
                    <AdvertiserDirectDeals
                      channel={EAllChannelType.DisplayAd}
                    />
                  }
                />

                <Route
                  path="digital-out-of-home"
                  element={
                    <AdvertiserDirectDeals channel={EAllChannelType.Dooh} />
                  }
                />
              </Route>
            )}

            <Route
              path="home"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <AdvertiserHome />
                </AuthenticatedRoute>
              }
              /* exact */
            />
            <Route
              path="creatives"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                  <AdvertiserCreativeOverview />
                </AuthenticatedRoute>
              }
              /* exact */
            >
              {featureFlags[PORTAL_FF_DA] && (
                <Route
                  path="display-ads"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <AdvertiserCreativeDisplayAds />
                    </AuthenticatedRoute>
                  }
                  /* exact */
                />
              )}

              {featureFlags[PORTAL_FF_DOOH_CREATIVES_VIEW] && (
                <Route
                  path="dooh"
                  element={
                    <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                      <DigitalOutOfHomeCreatives />
                    </AuthenticatedRoute>
                  }
                />
              )}
            </Route>
          </Route>

          <Route path="retailer/:retailerId/campaigns">
            <Route
              path="new/*"
              element={
                <AuthenticatedRoute authRole={EUserRole.AdvertiserCampaigns}>
                  <NewCampaign />
                </AuthenticatedRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </PortalRoutes>
    </>
  )
}

export default AdvertiserRoutes
